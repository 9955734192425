<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">Positions</h3>
        <span class="subtitle d-none d-md-inline-block">
          Define Position with Roles
        </span>
      </div>
      <base-button title="Create Position" action="primary" @click-btn="ui.modals.addNewPosition = true" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT')" />
    </div>
    <div class="text-center mt-5" v-if="ui.loading === true">
      <div class="spinner-border mt-5" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="page-content" v-else>
      <div class="search-wrapper">
        <img src="@/assets/icons/icon-search.svg" alt="Icon search" />
        <input type="text" placeholder="Search" v-model="query" />
      </div>
      <div class="table">
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Position</th>
              <th>Roles</th>
              <th>Users</th>
              <th v-if="isGranted('ROLE_CEO')"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="searchPosition.length === 0">
              <td colspan="5">
                <div class="modal-alert modal-alert_advice text-center">
                  No Results Found
                </div>
              </td>
            </tr>
            <tr v-for="(position, index) in searchPosition" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <div class="role-name" :class="{ 'delete' : !position.isActive }" @click="usersDetails(position)">
                  {{ position.name }}
                </div>
              </td>
              <td>
                <span>{{ position.roles.length }}</span>
              </td>
              <td>
                <div
                  v-if="position.users.length > 0"
                  class="d-flex cursor-pointer"
                  @click="usersDetails(position)"
                >
                  <div
                    class="table-avatar"
                    v-for="(agent, key) in getAssignees(position.users)"
                    :key="key"
                  >
                    <div v-tippy :content="agent.name">
                      <v-avatar
                        :src="agentPhoto(agent.photo)"
                        :size="36"
                        rounded
                      />
                    </div>
                  </div>
                  <div
                    class="table-avatar table-agent-count"
                    v-if="position.users.length > 5"
                  >
                    <span>+{{ position.users.length - 5 }}</span>
                  </div>
                </div>
                <span
                  v-else-if="isGranted('ROLE_CEO')"
                  class="add-users cursor-pointer"
                  @click="editPosition(position)"
                >
                  + Add Users
                </span>
                <span v-else>
                  No User
                </span>
              </td>
              <td v-if="isGranted('ROLE_CEO')">
                <div class="table-action">
                  <button
                    class="btn btn-control"
                    name="role"
                    @click="editPosition(position)"
                    v-tippy="{arrow: true, placement: 'top-center'}"
                    content="Edit"
                  >
                    <img src="@/assets/icons/icon-edit-normal.svg" alt="Edit" />
                  </button>
                  <button
                    class="btn btn-control"
                    name="role"
                    @click="deletePosition(position.id)"
                    v-if="position.isActive"
                    v-tippy="{arrow: true, placement: 'top-center'}"
                    content="Make Inactive"
                  >
                    <img alt="" src="@/assets/icons/icon-remove.svg">
                  </button>
                  <button
                    class="btn btn-control"
                    name="role"
                    @click="restorePosition(position.id)"
                    v-else
                    v-tippy="{arrow: true, placement: 'top-center'}"
                    content="Make Active"
                  >
                    <img alt="" src="@/assets/icons/icon-active.svg">
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <add-new-position
        v-if="ui.modals.addNewPosition === true"
        :tab="tabName"
        @success="getData"
        @close="closeModal"
      />
    </transition>

    <transition name="component-fade" mode="out-in">
      <role-details-modal
        :position="activePosition"
        v-if="ui.modals.roleDetails === true"
        @editRole="editPosition"
        @close="closeModal"
      />
    </transition>

    <transition name="component-fade" mode="out-in">
      <edit-position-modal
        v-if="ui.modals.editPosition === true"
        :id="activePosition.id"
        @success="getData"
        @close="closeModal"
      />
    </transition>
  </div>
</template>

<script>
import BaseButton from '../../components/BaseButton.vue';
import AddNewPosition from "./modals/AddNewPositionModal";
import EditPositionModal from "./modals/EditPositionModal";
import RoleDetailsModal from "./modals/RoleDetailsModal";
export default {
  name: "Positions",
  components: { RoleDetailsModal, EditPositionModal, AddNewPosition, BaseButton },
  data() {
    return {
      ui: {
        modals: {
          addNewPosition: false,
          editPosition: false,
          roleDetails: false,
        },
        loading: false,
      },
      query: "",
      tabName: "Permissions",
      positions: Array,
      activePosition: Object,
    };
  },
  methods: {
    editPosition(val) {
      this.activePosition = val;
      this.ui.modals.editPosition = true;
    },
    usersDetails(val) {
      this.activePosition = val;
      this.ui.modals.roleDetails = true;
    },
    closeModal() {
      this.activePosition = {};
      this.ui.modals.addNewPosition = false;
      this.ui.modals.roleDetails = false;
      this.ui.modals.editPosition = false;
    },
    deletePosition(id) {
      if (confirm("Are You Sure?") && id) {
        this.ui.loading = true;
        this.$http
          .delete(`/api/v1/position/${id}/delete`)
          .then((res) => {
            this.alertSuccess(res.data.message)
          })
          .catch((err) => {
            this.alertError(err.response.statusText);
          })
          .finally(() => this.getData());
      }
    },
    restorePosition(id) {
      if (confirm("Are You Sure?") && id) {
        this.ui.loading = true;
        this.$http
          .delete(`/api/v1/position/${id}/restore`)
          .then((res) => {
            this.alertSuccess(res.data.message)
          })
          .catch((err) => {
            this.alertError(err.response.statusText);
          })
          .finally(() => this.getData());
      }
    },
    getData() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/positions")
        .then((res) => {
          this.positions = res.data;
        })
        .catch((err) => {
          this.alertError(err.response.statusText);
        })
        .finally(() => (this.ui.loading = false));
    },
    getAssignees(assienees) {
      let assignee = [];
      assienees.map(function (value, key) {
        if (key < 5) {
          assignee.push(value);
        }
      });

      return assignee;
    },
  },
  computed: {
    searchPosition() {
      return this.positions.filter((position) =>
        position.name.toLowerCase().includes(this.query.toLowerCase())
      );
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  border-bottom: 1px solid #e2e2e2;
  span.subtitle {
    margin-left: 20px;
  }
  button {
    &.bg-green {
      max-width: max-content;
      width: 100%;
    }
  }
}

table.table {
  thead {
    tr {
      th {
        &:first-child {
          padding-left: 15px;
        }
      }
    }
  }
  tbody {
    tr {
      height: 70px;
      transition: all 0.3s;
      &:hover {
        background-color: #f6f6f6;
        .table-action {
          button {
            opacity: 1;
          }
        }
      }
      td {
        &:nth-child(n + 2) {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 18px;
        }
        span {
          opacity: 0.7;
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 21px;
          &.add-users {
            color: #017aff;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 21px;
            opacity: 1;
          }
        }
        .role-name {
          transition: all 0.3s;
          width: fit-content;
          cursor: pointer;
          &:after {
            display: block;
            content: "";
            width: 0%;
            height: 1px;
            background: black;
            transition: all 0.3s;
          }
          &:hover {
            &:after {
              width: 100%;
              background: black;
            }
          }

          &.delete {
            text-decoration: line-through;
          }
        }
      }
      .table-avatar {
        height: 40px;
        border-radius: 50%;
        border: 2px solid #ffffff;
        &:nth-child(n + 2) {
          margin-left: -10px;
        }
        &.table-agent-count {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 40px;
          height: 40px;
          background-color: #d8d8d8;
          span {
            color: #4a4a4a;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
      }
      .table-action {
        display: flex;
        justify-content: flex-end;
        opacity: 1;
        transition: all 0.3s;
        button {
          margin: 0 0 0 9px;
          opacity: 0;
          &.active {
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0.05);
            opacity: 1;
          }
        }
      }
    }
  }
}

.search-wrapper {
  margin-bottom: 12px;
}

.page-content {
  padding: 18px 30px;
}

.light-theme {
  border: 1px solid #efefef;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07);
  padding: 0;
}
.table-menu-block {
  min-width: 180px;
  border-radius: 8px;
  ul {
    padding: 12px 0;
    li {
      text-align: initial;
      padding: 4px 11px;
      &:hover {
        img {
          transition: all 0.3s;
          opacity: 1;
        }
      }
      &.action-label {
        padding: 4px 13px;
        &:hover {
          background: none;
        }
      }
    }
  }
}
</style>
