<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Create Position</h3>
      </div>
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div id="modal-body-scroll" v-show="ui.loading === false">
        <div class="modal-block-container mt-3 mb-0">
          <div class="form-check form-switch p-0">
            <label
              :class="{ active: formData.isActive === false }"
              class="form-check-label ml-0"
              for="fieldIsActive"
              >Inactive</label
            >
            <input
              id="fieldIsActive"
              v-model="formData.isActive"
              class="form-check-input m-0"
              type="checkbox"
            />
            <label
              :class="{ active: formData.isActive === true }"
              class="form-check-label"
              for="fieldIsActive"
              >Active</label
            >
          </div>
          <div class="form-group">
            <label for="fieldRoleName"> Position Name </label>
            <input
              id="fieldRoleName"
              v-model="formData.name"
              :class="{ 'input-filled': formData.name.length }"
              class="form-control"
              type="text"
            />
          </div>
        </div>
        <div class="page-tabs d-flex justify-content-between">
          <ul class="tab-list">
            <li
              :class="{ active: tabName === 'Roles' }"
              @click="setTab('Roles')"
            >
              Roles
            </li>
            <li
              :class="{ active: tabName === 'Users' }"
              @click="setTab('Users')"
            >
              Users
            </li>
          </ul>
        </div>
        <div v-if="tabName === 'Roles'" class="modal-body">
          <div class="permissions mt-4">
            <div class="permission-title">General</div>
            <div class="modal-body-container">
              <div
                class="form-check form-switch pb-3"
                v-for="(role, index) in formData.roles"
                :key="index"
              >
                <input
                  :id="index"
                  class="form-check-input"
                  type="checkbox"
                  v-model="role.value"
                />
                <label class="form-check-label" :for="index">{{
                  role.label
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="modal-body">
          <div>
            <div class="col-6 mt-4">
              <div class="search-wrapper">
                <img alt="Icon search" src="@/assets/icons/icon-search.svg" />
                <input
                  placeholder="Search"
                  type="text"
                  v-model="formData.query"
                />
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <div class="selected-users">
                Users Selected ({{ countSelected }})
              </div>
            </div>
            <div class="row mt-3 users-list w-100 m-auto">
              <div
                v-for="user in userList"
                :key="user.id"
                class="col-12 users-list_item"
              >
                <div class="row cursor-pointer" @click="checkUser(user)">
                  <div class="col-8">
                    <div class="d-flex align-items-center">
                      <div class="form-check">
                        <input
                          :id="'user' + user.id"
                          v-model="user.isChecked"
                          class="form-check-input"
                          type="checkbox"
                        />
                      </div>
                      <v-avatar
                        :src="agentPhoto(user.photo)"
                        :size="42"
                        rounded
                        username="John Smith"
                      />
                      <span class="users-list_item--title">
                        {{ user.name }}
                      </span>
                    </div>
                  </div>
                  <div class="col-4 text-end my-auto">
                    <span class="users-list_item--position">
                      {{ user.position ? user.position : "N/A" }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                v-if="userList.length === 0"
                class="modal-alert modal-alert_advice text-center"
              >
                No Results Found
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between" v-if="!ui.loading">
        <div class="d-flex w-50">
          <base-button title="Save" action="secondary-default" @click-btn="savePosition" :disabled="showSubmitBtn" :loading="ui.saving" />
          <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: "AddPositionModal",
  components: { BaseButton },
  data() {
    return {
      tabName: "Roles",
      countSelected: 0,
      formData: {
        query: "",
        name: "",
        isActive: true,
        roles: [],
      },
      users: [],
      ui: {
        loading: false,
        saving: false,
        ps: null,
      },
    };
  },
  methods: {
    savePosition() {
      this.ui.saving = true;
      let formData = {
        name: this.formData.name,
        isActive: this.formData.isActive,
        roles: this.formData.roles
          .filter((role) => role.value)
          .map(function (role) {
            return role.label;
          }),
        users: this.users
          .filter((user) => user.isChecked)
          .map(function (user) {
            return user.id;
          }),
      };

      this.$http
        .post(`/api/v1/position/new`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message);
          this.$emit("success");
          this.$emit("close");
        })
        .catch((err) => {
          this.alertError(err.response.statusText);
        })
        .finally(() => (this.ui.saving = false));
    },
    setTab(value) {
      this.tabName = value;
    },
    getData() {
      this.ui.loading = true;
      this.$http
        .get(`/api/v1/position/new`)
        .then((res) => {
          this.formData.roles = Object.keys(res.data.roles).map((value) => {
            return {
              label: value,
              value: res.data.roles[value],
            };
          });

          this.users = res.data.users;
        })
        .catch((err) => {
          this.alertError(err.response.statusText);
        })
        .finally(() => (this.ui.loading = false));
    },
    countUserSelected(val) {
      if (!val) {
        this.countSelected++;
      } else {
        this.countSelected--;
      }
    },
    checkUser(user) {
      const item = user;
      this.countUserSelected(item.isChecked);
      this.users = this.users.map((el) => {
        if (el.id === item.id) {
          el.isChecked = !el.isChecked;
        }
        return el;
      });
    },
  },
  created() {
    this.getData();
  },
  computed: {
    userList() {
      return this.users.filter((user) =>
        user.name.toLowerCase().includes(this.formData.query.toLowerCase())
      );
    },
    showSubmitBtn() {
      let roles = this.formData.roles.filter((role) => role.value);

      return (
        this.formData.name.trim() === "" ||
        this.countSelected === 0 ||
        roles.length === 0 ||
        this.ui.saving
      );
    },
  },
  mounted() {
    const scrollbar = document.getElementById("modal-body-scroll");
    this.ui.ps = new PerfectScrollbar(scrollbar);
  },
};
</script>

<style lang="scss" scoped>
#modal-body-scroll {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
}

.modal-header {
  position: relative !important;
}

.modal-block-container {
  padding: 0 30px 25px 30px;
  border-bottom: 1px solid #e7e8e8;

  label {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 11px;
  }

  .form-switch {
    margin-bottom: 24px;

    input {
      margin-top: 0;
    }

    label {
      color: #000000;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      opacity: 0.46;
      transition: all 0.3s;

      &.active {
        opacity: 1;
      }

      &:first-child {
        margin-right: 12px;
      }

      &:last-child {
        margin-left: 12px;
      }
    }
  }
}

.modal-body {
  padding: 0 30px 120px 30px;

  &-container {
    padding-bottom: 17.5px;
    margin-bottom: 36px;
    .form-switch {
      input {
        &:checked {
          background-color: #266fe5 !important;
        }
      }
    }
  }

  .permission-title {
    opacity: 0.46;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 24px;
  }
}

.modal-footer {
  button {
    &.btn-primary,
    &.btn-cancel {
      max-width: 110px;
      width: 100%;
      margin-right: 12px;
    }
    &.btn-control {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.users-list {
  &_item {
    padding: 12px 0;
    border-bottom: 1px solid #e7e8e8;

    .form-check {
      margin-right: 14px;

      input {
        height: 18px;
        width: 18px !important;
        max-width: 18px;

        &:checked {
          background-color: #0d6efd !important;
          border-color: #0d6efd !important;
        }
      }
    }

    &--title {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      margin-left: 18px;
    }

    &--position {
      opacity: 0.46;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: right;
    }
  }
}

.selected-users {
  opacity: 0.46;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

button {
  &.btn-link {
    color: #017aff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: normal;
    padding: 0;
  }

  &.bg-green {
    max-width: 110px;
    width: 100%;
  }
}

div.table {
  table {
    tbody {
      tr {
        vertical-align: middle;
        border-top: 0;
        &:not(:first-child) {
          td {
            border-top: 1px solid #e7e8e8;
          }
        }
        &:nth-child(even) {
          background-color: #ffffff !important;
        }
        td {
          padding: 16px 0;
          border-top: 0;
          &:first-child {
            padding-left: 0;
            width: 10%;
          }
          &:nth-child(n + 2) {
            width: 5%;
          }
          .form-check {
            margin: 0;
            input {
              margin: 0;
            }
          }
          .form-check-input {
            transition: all 3s;
            &:checked {
              background: url("~@/assets/icons/icon-done.svg");
              background-position-x: center;
              background-position-y: center;
            }
            &:hover {
              background: url("~@/assets/icons/icon-done.svg");
              background-position-x: center;
              background-position-y: center;
            }
          }
          input {
            height: 18px;
            max-width: 18px;
            border-radius: 50%;
          }
          label {
            margin: 0 0 0 10px;
            opacity: 0.7;
            color: #000000;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
      }
    }
  }
}

.search-wrapper {
  margin: 24px 0;
  max-width: 220px;
}

.permissions {
  .form-switch {
    input {
      margin-top: 0;
      &:checked {
        background-color: #266fe5 !important;
        border-color: #266fe5 !important;
      }
    }
  }
  .modal-body-container {
    table.table {
      padding: 0;
      tbody {
        tr {
          padding: 0;
          td {
            padding-bottom: 13.5px;
          }
        }
      }
    }
  }
}
</style>
