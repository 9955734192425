<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3 :class="{ 'delete' : !position.isActive }">{{ position.name }}</h3>
        <button v-if="isGranted('ROLE_CEO')" class="btn btn-control" @click="$emit('editRole', position)">
          <img src="@/assets/icons/icon-edit-normal.svg" alt="Edit role" />
        </button>
      </div>

      <div class="modal-body" id="modal-body-scroll">
        <div class="text-center mt-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="search-wrapper mt-1">
            <img alt="Icon search" src="@/assets/icons/icon-search.svg" />
            <input placeholder="Search" type="text" v-model="query" />
          </div>
          <div class="modal-body-container mt-4 border-0">
            <div class="d-flex align-items-center justify-content-between">
              <div class="selected-users">Assign Users</div>
            </div>
            <div class="row mt-3 users-list w-100 m-auto">
              <div
                v-for="user in userList"
                :key="user.id"
                class="col-12 users-list_item"
              >
                <div class="row cursor-pointer">
                  <div class="col-8">
                    <div class="d-flex align-items-center">
                      <v-avatar
                        :src="agentPhoto(user.photo)"
                        :size="42"
                        rounded
                      />
                      <span class="users-list_item--title">
                        {{ user.name }}
                      </span>
                    </div>
                  </div>
                  <div class="col-4 text-end my-auto">
                    <span class="users-list_item--position">
                      {{ user.position }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                v-if="userList.length === 0"
                class="modal-alert modal-alert_advice text-center"
              >
                No Results Found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";

export default {
  name: "RoleDetailsModal",
  props: {
    position: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      users: [],
      query: "",
      ui: {
        loading: false,
        ps: null,
      },
    };
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
        this.users = this.position.users;
      }, 1000);
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    const scrollbar = document.getElementById("modal-body-scroll");
    this.ui.ps = new PerfectScrollbar(scrollbar);
  },
  computed: {
    userList() {
      return this.users.filter((user) =>
        user.name.toLowerCase().includes(this.query.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#modal-body-scroll {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
}
.modal-header {
  button {
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.users-list {
  &_item {
    padding: 12px 0;
    border-bottom: 1px solid #e7e8e8;

    .form-check {
      margin-right: 14px;

      input {
        height: 18px;
        width: 18px !important;
        max-width: 18px;

        &:checked {
          background-color: #0d6efd !important;
          border-color: #0d6efd !important;
        }
      }
    }

    &--title {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      margin-left: 18px;
    }

    &--position {
      opacity: 0.46;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: right;
    }
  }
}

.selected-users {
  opacity: 0.46;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.delete {
  text-decoration: line-through;
}
</style>
